/* Typo */
// Marianne Regular
@font-face {
  font-family: "marianne-regular";
  src: url("../../public/fonts/Marianne/Marianne-Regular.woff2") format("woff2"),
  url("../../public/fonts/Marianne/Marianne-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

$font-Marianne-Regular: "marianne-regular", arial, sans-serif;
.marianne-regular {
  font-family: $font-Marianne-Regular !important;
}

// Marianne Bold
@font-face {
  font-family: "marianne-bold";
  src: url("../../public/fonts/Marianne/Marianne-Bold.woff2") format("woff2"),
  url("../../public/fonts/Marianne/Marianne-Bold.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

$font-Marianne-Bold: "marianne-bold", arial, sans-serif;
.marianne-bold {
  font-family: $font-Marianne-Bold !important;
  font-weight: bold;
}

// Roboto Regular
@font-face {
  font-family: "roboto-regular";
  src: url("../../public/fonts/Roboto/roboto-regular.eot");
  src: url("../../public/fonts/Roboto/roboto-regular.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Roboto/roboto-regular.woff2") format("woff2"),
  url("../../public/fonts/Roboto/roboto-regular.woff") format("woff"),
  url("../../public/fonts/Roboto/roboto-regular.ttf") format("truetype"),
  url("../../public/fonts/Roboto/roboto-regular.svg#roboto-regular") format("svg");
  font-style: normal;
  font-weight: normal;
}

$font-Roboto-Regular: "roboto-regular", arial, sans-serif;
.roboto-regular {
  font-family: $font-Roboto-Regular !important;
}

// Roboto Italic
@font-face {
  font-family: "roboto-italic";
  src: url("../../public/fonts/Roboto/roboto-italic.eot");
  src: url("../../public/fonts/Roboto/roboto-italic.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Roboto/roboto-italic.woff2") format("woff2"),
  url("../../public/fonts/Roboto/roboto-italic.woff") format("woff"),
  url("../../public/fonts/Roboto/roboto-italic.ttf") format("truetype"),
  url("../../public/fonts/Roboto/roboto-italic.svg#roboto-italic") format("svg");
  font-style: normal;
  font-weight: normal;
}

$font-Roboto-Italic: "roboto-italic", arial, sans-serif;
.roboto-italic {
  font-family: $font-Roboto-Italic !important;
  font-style: italic;
}

// Roboto Bold
@font-face {
  font-family: "roboto-bold";
  src: url("../../public/fonts/Roboto/roboto-bold.eot");
  src: url("../../public/fonts/Roboto/roboto-bold.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Roboto/roboto-bold.woff2") format("woff2"),
  url("../../public/fonts/Roboto/roboto-bold.woff") format("woff"),
  url("../../public/fonts/Roboto/roboto-bold.ttf") format("truetype"),
  url("../../public/fonts/Roboto/roboto-bold.svg#roboto-bold") format("svg");
  font-style: normal;
  font-weight: normal;
}

$font-Roboto-Bold: "roboto-bold", arial, sans-serif;
.roboto-bold {
  font-family: $font-Roboto-Bold !important;
  font-weight: bold;
}