/* Info-bulle */
:root {
    --tooltip-text-color: #ffffff;
    --tooltip-background-color: #273961;
    --tooltip-h-margin: 0.8725rem; // horizontal
    --tooltip-v-margin: 2.325rem; // vertical
    --tooltip-arrow-size: 0.375rem;
}

.fr-app-tooltip-info-color {
    color: $info-200;
}

.fr-app-tooltip-side {
    pointer-events: auto !important;
    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }
}

.fr-app-tooltip-wrapper {
    display: inline-block;
    position: relative;

    .fr-app-tooltip-trigger {
        &.fr-app-btn-icon,
        *[class*="ri-"] {
            width: 1.325rem;
            height: 1.325rem;
        }

        *[class*="ri-"] {
            font-size: 1.325rem;

            &:hover {
                color: var(--tooltip-background-color);
            }
        }

        &.fr-app-btn-icon + .fr-app-tooltip {
            &.top {
                top: auto;
                bottom: 1.8725rem;
            }
        }
    }

    .fr-app-tooltip {
        position: absolute;
        border-radius: 0.2875rem;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.225rem 0.75rem;
        color: var(--tooltip-text-color);
        background: var(--tooltip-background-color);
        font-size: 0.875rem;
        letter-spacing: 0.02rem;
        line-height: 1.5;
        z-index: 100;
        white-space: nowrap;
        max-width: 300px;

        &::before {
            content: " ";
            left: 50%;
            border: solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: var(--tooltip-arrow-size);
            margin-left: calc(var(--tooltip-arrow-size) * -1);
        }

        &.top {
            //TODO: définir une valeur défaut selon les cas générals
            top: calc(var(--tooltip-v-margin) * -1);

            &::before {
                top: 100%;
                border-top-color: var(--tooltip-background-color);
            }
        }

        &.bottom {
            //TODO: définir une valeur défaut selon les cas générals
            bottom: calc(var(--tooltip-v-margin) * -1);

            &::before {
                bottom: 100%;
                border-bottom-color: var(--tooltip-background-color);
            }
        }

        &.right {
            left: calc(100% + var(--tooltip-h-margin));
            top: 50%;
            transform: translateX(0) translateY(-50%);

            &::before {
                left: calc(var(--tooltip-arrow-size) * -1);
                top: 50%;
                transform: translateX(0) translateY(-50%);
                border-right-color: var(--tooltip-background-color);
            }
        }

        &.left {
            left: auto;
            right: calc(100% + var(--tooltip-h-margin));
            top: 50%;
            transform: translateX(0) translateY(-50%);

            &::before {
                left: auto;
                right: calc(var(--tooltip-arrow-size) * -2);
                top: 50%;
                transform: translateX(0) translateY(-50%);
                border-left-color: var(--tooltip-background-color);
            }
        }
    }
}

.with-fr-app-tooltip {
    position: relative;

    > .fr-input-group + .fr-app-tooltip-container {
        position: absolute;
        top: 2.5rem;
        right: 1rem;
    }

    > .fr-checkbox-group {
        margin-right: 0.625rem !important;

        &,
        & + .fr-app-tooltip-container {
            display: inline-block;
        }

        & + .fr-app-tooltip-container {
            margin-right: 2rem;

            .fr-app-tooltip-wrapper {
                .fr-app-tooltip-trigger {
                    *[class*="ri-"] {
                        vertical-align: -0.38725rem;
                    }
                }
            }
        }
    }
}

/* Overwrite ReactTooltip css */
.__react_component_tooltip .multi-line {
    padding: 0 !important;
}

#destination-info-tooltip {
    font-family: $font-Marianne-Regular;
}
