.fr-app-modal {
  p,
  label {
    color: $grey-typo;
  }

  .fr-link--close {
    color: $grey-link;
  }

  .fr-modal__title {
    span[class*="ri-"] {
      font-size: 2rem;
      font-weight: normal;
      vertical-align: -0.4875rem;
    }
  }

  .fr-app-btn-icon.icon-not-underline {
    span[class*="ri-"] {
      border-bottom: solid 2px #ffffff;
      margin-right: 0;
      padding-right: 0.5rem;
    }
  }

  .fr-modal__footer {
    border-top: solid 1px $grey-border;
  }
}

/* Modal Toggle btns */
.fr-app-toggle-modal-btn-container {
  .fr-app-modal-toggle-btn {
    text-transform: uppercase;
    border-radius: 0;
    position: fixed;

    /* Message à diffuser toggle */
    &.msg-diffuser-modal-toggle {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.5rem;
      right: 0;
      bottom: 11.25rem;
      background-color: $info-200;

      span[class*="ri-"] {
        font-size: 1.5rem;
      }

      &:hover,
      &:focus {
        background-color: $info-200-hover;
      }

      &:active {
        background-color: $info-200-active;
      }
    }

    /* Assistance toggle */
    &.assistance-modal-toggle {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      padding: 0.5rem 1rem;
      letter-spacing: 0.025rem;
      bottom: 5.25rem;
      right: -3rem;
      z-index: 10;
    }
  }
}

/* Message à diffuser modal */
.msg-diffuser-modal {
  .fr-modal__title {
    color: $info-200 !important;
  }

  .fr-modal__content {
    a {
      &:hover,
      &:focus {
        color: $info-200-hover;
      }

      &:active {
        color: $info-200-active;
      }
    }
  }

  .fr-modal__footer {
    margin-top: -2rem;
    padding: 1.5rem 2rem 1.75rem;
    display: table;
    width: 100%;

    & > .fr-checkbox-group,
    & > .close-btn-container {
      display: table-cell;
    }

    & > .close-btn-container {
      text-align: right;

      .fr-btn {
        background-color: $info-200;

        &:hover,
        &:focus {
          background-color: $info-200-hover;
        }

        &:active {
          background-color: $info-200-active;
        }
      }
    }

    & > .fr-checkbox-group {
      input[type="checkbox"] {
        &:checked + label:before {
          --blend: inherit;
          background-color: $info-200-hover;
          box-shadow: none;
        }
      }
    }
  }
}

/* Assistance bloc style modal */
.assistance-modal {
  .fr-app-linklist-container {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .fr-modal.assistance-modal {
    display: block;
    position: fixed;
    left: unset;
    top: unset;
    right: 0;
    bottom: 2rem;
    width: 384px;
    height: auto;
    min-height: max-content;

    &:after,
    &:before {
      display: none;
    }

    .fr-container {
      padding: 0;
    }

    .fr-col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

/* APP ICPE */
.fr-app-icpe {
  &.fr-app-modal:not(.msg-diffuser-modal) {
    .fr-modal__title {
      color: $app-green-typo;
    }
  }
}

/* APP EAUNATURE */
.fr-app-eaunature {
  &.fr-app-modal:not(.msg-diffuser-modal) {
    .fr-modal__title {
      color: $app-orange-typo;
    }
  }
}

.badge-has-read-message {
  position: absolute;
  right: -0.3em;
  bottom: 1.75em;
}
