/* Color */
// Couleur primaire
$app-green: #447049;
$app-green-typo: $app-green;
$app-orange: #cf4b0d;
$app-orange-typo: #bd410b;

// Couleur primaire active
$app-green-active: #325336;
$app-orange-active: #a33707;

// Couleur secondaire
$app-green-bg: #ddffdd;
$app-red-bg: #ff000090;
$app-yellow-bg: #bfc80e90;
$app-orange-bg: #feecc2;

// Couleur ligne tableau
$app-pink-bg: #ff77aa40;

// Couleur Typos
$grey-typo: #202328;
$grey-link: #6a6a6a;
$grey-link-active: #f0f0f0;
$grey-link-disabled: #666666;

// Couleur Fond
$grey-bg: #e5e5e5;
$grey-form-field-bg: #f0f0f0;
$grey-body-bg: #eeeeee;
$orange-bg: #fe9300;

// Couleur Contour
$grey-border: #cecece;
$grey-th-border: $grey-link;

// Couleur système
$info-425: #0063cb;

$info-200: #273961;
$info-200-hover: #45619f;
$info-200-active: #5576c0;

$success-425: #18753c;
$autre-choix-session-425: #23ba6a;
$yellow-warning-425: #bfc80e;
$warning-425: #b34000;
$error-425: #ce0500;

// Couleur badge
$purple: #403294;
$purple-bg: #eae6ff;

.fr-app-orange-bg {
  background-color: $orange-bg !important;
}

.fr-app-green-bg {
  background-color: $app-green-bg !important;
}

.fr-app-green {
  color: $app-green !important;
}

.fr-app-red-bg {
  background-color: $app-red-bg !important;
}

.fr-app-yellow-bg {
  background-color: $app-yellow-bg !important;
}

.fr-app-pink-bg {
  background-color: $app-pink-bg !important;
}

:export {
  appgreen: $app-green;
  appgreentypo: $app-green-typo;
  apporange: $app-orange;
  apporangetypo: $app-orange-typo;
}
