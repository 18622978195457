.sitemap {
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem 0;

    li {
        list-style: none;
        padding-bottom: 0.125rem;
    }
}

.sitemap-title {
    width: fit-content;
    font-family: "marianne-bold", arial, sans-serif;
    font-size: 20px;
}

.sitemap-subtitle {
    width: fit-content;
    font-family: "marianne-bold", arial, sans-serif;
    font-size: 18px;
    margin-left: 1rem;
}

.sitemap-link-level-1 {
    width: fit-content;
    margin-left: 1rem;
}

.sitemap-link-level-2 {
    width: fit-content;
    margin-left: 2rem;
}

.sitemap-margin {
    display: block;
    margin-bottom: 1rem;
}

.not-clickable {
    background-image: none;
    margin-bottom: 0px;
    color: #6a6a6a;
}
