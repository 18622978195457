/* Icon */
@mixin triangle-down($height, $half-width, $color) {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  border-top: $height solid $color;
  border-right: $half-width solid transparent;
  border-left: $half-width solid transparent;
  margin: auto;
  left: 0;
  right: 0;
}

// hover effect
.fr-app-btn-icon {
  display: flex;

  &:hover,
  &:focus,
  &:active,
  &.on,
  &.active {
    .ri-book-line:before {
      content: "\EAD6";
    }

    .ri-file-user-line:before {
      content: "\ED16";
    }

    .ri-settings-3-line:before {
      content: "\F0E5";
    }

    .ri-group-line:before {
      content: "\EDE2";
    }

    .ri-logout-circle-r-line:before {
      content: "\EEDD";
    }

    .ri-home-2-line:before {
      content: "\EE18";
    }

    .ri-message-3-line:before {
      content: "\EF45";
    }

    .ri-mail-line:before {
      content: "\EEF3";
    }

    .ri-pencil-line::before {
      content: "\EFDF";
    }

    .ri-file-pdf-line::before {
      content: "\ECFC";
    }

    .ri-question-line:before {
      content: "\F044";
    }

    .ri-delete-bin-2-line:before {
      content: "\EC1D";
    }

    .ri-close-circle-line:before {
      content: "\EB96";
    }
  }
}
// Icons non incluses in Remix Icons
.fr-app-icon {
  background-repeat: no-repeat;
}

.fr-app-icon-commission {
  background-image: url(../assets/images/icon-commission.svg);
}

.fr-app-icon-warning {
  background-image: url(../assets/images/icon-warning.svg);
}

.fr-app-icon-finalisee {
  background-image: url(../assets/images/icon-F.svg);
}

.fr-app-icon-choix-1 {
  background-image: url(../assets/images/icon-choix-1.svg);
}

.fr-app-icon-choix-2 {
  background-image: url(../assets/images/icon-choix-2.svg);
}

.fr-app-icon-R-red {
  background-image: url(../assets/images/icon-R-red.svg);
}

.fr-app-icon-file-PDF {
  background-image: url(../assets/images/icon-file-PDF.svg);
  display: block;
  width: 45px;
  height: 35px;
}

.fr-app-icon-T-red {
  background-image: url(../assets/images/icon-T-red.svg);
}

.fr-app-icon-T-green {
  background-image: url(../assets/images/icon-T-green.svg);
}
