/* Header */
@mixin fr-app-header__menu($primary-color) {
  background-color: $primary-color;
  color: #ffffff;
}

.fr-header {
  &.fr-app-header {
    box-shadow: none;
    z-index: 9;

    // Header haut
    .fr-header__body {
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
      position: relative;
      z-index: 15;
      background-color: #ffffff;
    }

    .fr-header__body-row {
      display: block;
    }

    .fr-header__brand {
      filter: none;
    }

    .fr-header__brand-top,
    .fr-header__service {
      width: auto;
    }

    .fr-header__service {
      box-shadow: none;
      margin: 0;
      padding: 1rem;
    }

    .header-logo-gouvernement {
      height: 148px;
    }

    .fr-logo {
      font-family: $font-Marianne-Bold;
      letter-spacing: 0.025rem;
      font-size: 0.8rem;
      padding: 0.3rem 1rem 0.15rem;
      color: $grey-typo;

      &:before {
        background-size:
          2.1rem 0.8375rem,
          2.1rem 0.7425rem,
          0;
        height: 0.75rem;
        width: 2.1rem;
        margin-bottom: 0.125rem;
      }

      &:after {
        background-position: -0.255rem calc(100% + 1.35rem);
        background-size: 5rem 3.25rem;
      }
    }

    .fr-header__service-title,
    .fr-header__service-tagline {
      color: $grey-typo;
    }

    .fr-header__service-tagline {
      font-family: $font-Roboto-Italic;
      font-size: 0.875rem;
    }

    .fr-header__tools-links {
      display: flex;
      box-shadow: inset 0 1px 0 0 var(--border-default-grey);

      .fr-app-btn-icon {
        & > span {
          width: 1.125rem;
          height: 1.5rem;
          overflow: hidden;

          span[class*="ri-"] {
            font-size: 1.125rem !important;
          }
        }
      }
    }

    .fr-links-group {
      margin: 0;
      padding: 0.5rem 0.425rem 0.625rem;

      & > li {
        display: inline-flex;
        max-width: 100%;
        width: auto;
      }

      .fr-link {
        margin: 0;

        [class^="ri-"].icon-left {
          font-size: 1rem;
          margin-right: 0.325rem;
          vertical-align: -0.1rem;

          &::after {
            vertical-align: -0.1rem;
          }
        }

        &.link-quick_access {
          &:after {
            @include triangle-down(0.3125rem, 0.3125rem, $grey-link);
            margin-top: 0.7rem;
            margin-left: 0.35rem;
          }
        }
      }
    }

    // Header bas
    .fr-header__menu {
      .fr-app-user-info {
        display: flex;
        align-items: center;
        height: 100%;

        .fr-app-btn_with_icon_v_header {
          display: none;
        }
      }

      .fr-app-user-actions {
        text-align: center;
        max-width: 96px;

        & > * {
          display: inline-block;
        }
      }

      .fr-app-btn_with_icon_v_header {
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0.02rem;
        font-size: 0.625rem;
        padding: 0.815rem 0.425rem 0.25rem;
        min-width: 3.625rem;
        line-height: 1rem;
        height: 57.55px;
        text-align: center;

        span {
          &[class*="ri-"] {
            font-size: 1.5rem;
          }
        }

        &.no-text {
          span:not([class*="ri-"]) {
            display: none;
          }
        }

        &.on,
        &.active,
        &:hover,
        &:focus,
        &:active {
          background-image: none;
        }

        &.btn-menu {
          padding-left: 0.925rem;
          padding-right: 0.925rem;
          position: relative;
          margin-right: 1.5rem;
          margin-top: 1px;

          span {
            display: block;
            margin-bottom: 0.45rem;
          }

          &.on:after {
            position: absolute;
            bottom: -0.6rem;
          }
        }

        @-moz-document url-prefix() {
          &.dff {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .fr-header {
    &.fr-app-header {
      // Header bas
      .fr-header__menu {
        .fr-app-user-actions {
          text-align: right;
          max-width: none;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .fr-header {
    &.fr-app-header {
      // Header bas
      .fr-header__menu {
        .fr-app-btn_with_icon_v_header {
          &.btn-profile_list {
            margin-right: 0.725rem;
            letter-spacing: 0.03rem;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .fr-header {
    &.fr-app-header {
      // Header haut
      .fr-header__body-row {
        display: flex;
      }

      .fr-header__tools-links {
        box-shadow: none;
      }

      .fr-links-group {
        margin: 0 -0.5rem;
        padding: 0;

        .fr-link {
          margin: 0 0.5rem 1rem;
        }
      }

      // Header bas
      .fr-header__menu {
        .fr-app-user-info {
          .fr-app-btn_with_icon_v_header {
            display: inline-block;
          }
        }
      }
    }
  }
}

/* APP ICPE */
.fr-app-icpe {
  // Header
  .fr-app-header {
    // Header bas
    .fr-header__menu {
      @include fr-app-header__menu($app-green);

      .fr-app-btn_with_icon_v_header {
        &.on,
        &.active,
        &:hover,
        &:focus,
        &:active {
          background-color: $app-green-bg;
        }

        &.on,
        &.active,
        &:hover,
        &:active {
          color: $app-green-typo;
        }

        &:focus {
          color: $app-green-active;
        }

        &.btn-menu {
          &.on:after {
            @include triangle-down(0.625rem, 0.5rem, $app-green-bg);
          }
        }

        &.btn-profile_list {
          &:hover,
          &:active {
            &:after {
              border-top-color: $app-green-typo;
            }
          }

          &:focus {
            &:after {
              border-top-color: $app-green-active;
            }
          }
        }
      }
    }
  }
}

/* APP EAUNATURE */
.fr-app-eaunature {
  // Header
  .fr-app-header {
    // Header bas
    .fr-header__menu {
      @include fr-app-header__menu($app-orange);

      .fr-app-btn_with_icon_v_header {
        &.on,
        &.active,
        &:hover,
        &:focus,
        &:active {
          background-color: $app-orange-bg;
        }

        &.on,
        &.active,
        &:hover,
        &:active {
          color: $app-orange-typo;
        }

        &:focus {
          color: $app-orange-active;
        }

        &.btn-menu {
          &.on:after {
            @include triangle-down(0.625rem, 0.5rem, $app-orange-bg);
          }
        }

        &.btn-profile_list {
          &:hover,
          &:active {
            &:after {
              border-top-color: $app-orange-typo;
            }
          }

          &:focus {
            &:after {
              border-top-color: $app-orange-active;
            }
          }
        }
      }
    }
  }
}
