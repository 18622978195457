/* Form */
@mixin fr-app-input-select_focus($focus-border-color) {
  box-shadow: inset 0 -2px 0 0 $focus-border-color !important;
}

@mixin fr-app-input_radio($checked-bg-color) {
  box-shadow: inset 0 0 0 1px $checked-bg-color, inset 0 0 0 4px #ffffff, inset 0 0 0 12px $checked-bg-color !important;
}

@mixin fr-app-input_checkbox($checked-bg-color) {
  --blend: inherit !important;
  background-color: $checked-bg-color !important;
}

.fr-app-form,
.fr-app-bloc-filtre {

  .fr-app-padding-top {
    padding-top: 1.25rem !important;
  }

  .remark.roboto-italic {
    span.error {
      font-family: $font-Roboto-Regular !important;
      font-size: 0.875rem !important;
      vertical-align: -0.175rem;
      font-style: normal;
      margin: 0 0.15rem 0 0.1rem;
    }
  }

  .fr-input-wrap {
    &[class*=" fr-fi-"],
    &[class*=" fr-icon-"],
    &[class^="fr-fi-"],
    &[class^="fr-icon-"] {
      &::before {
        top: 2.75rem;
      }
    }
  }

  [class*=" fr-fi-"]:after,
  [class*=" fr-fi-"]:before,
  [class*=" fr-icon-"]:after,
  [class*=" fr-icon-"]:before,
  [class^="fr-fi-"]:after,
  [class^="fr-fi-"]:before,
  [class^="fr-icon-"]:after,
  [class^="fr-icon-"]:before {
    background-color: inherit;
  }

  .required-field {
    label:after {
      content: " *";
      color: #f60700;
    }
  }

  .required-fieldset {
    legend:after {
      content: " *";
      color: #f60700;
    }
  }

  .fr-input-group,
  .fr-select-group {
    margin-bottom: 0 !important;
  }

  .fr-error-text {
    margin-top: 0.625rem;
  }

  .fr-upload-group {
    > .fr-label {
      display: inline-block;
      margin-right: 0.25rem;
    }
    .fr-upload {
      margin-top: 0.55rem;
    }
  }
  .fr-hint-text {
    font-family: $font-Roboto-Italic !important;
    font-size: 0.75rem !important;
    color: $grey-typo !important;
  }

  .fr-app-form-group {
    .fr-app-form-group-item {
      margin-bottom: 1.25rem !important;
    }
  }

  fieldset,
  .fr-fieldset {
    border: solid 1px $grey-border;
    padding: 0 1.25rem 0.5rem;
    margin-bottom: 0.55rem;

    > legend {
      text-align: center;
      padding: 0 1rem;
    }

    .fr-radio-group {
      &:first-child {
        margin-top: -0.325rem;
      }
    }

    .fieldset-last-item-no-margin-bottom {
      margin-bottom: 1.25rem;

      &.fr-radio-group {
        margin-bottom: 0.725rem;

        input[type="radio"] + label {
          padding-bottom: 0;
        }
      }
    }
  }

  div[class*="fr-col-"] {
    margin-bottom: 1.25rem;

    &.fr-col-6:not(.align-centre):not(.reverse) {
      &:nth-child(odd) {
        padding-right: 0.5rem;
      }

      &:nth-child(even):not(.reverse) {
        padding-left: 0.5rem;
      }
    }

    &.fr-col-6 {
      &:nth-child(odd) {
        &.reverse {
          padding-left: 0.5rem;
          padding-right: 0;
        }
      }

      &:nth-child(even) {
        &.reverse {
          padding-left: 0;
          padding-right: 0.5rem;
        }
      }
    }
  }

  .fr-btns-group {
    padding-top: 0.625rem;
  }

  label,
  legend,
  input,
  select,
  textarea,
  .form-field-prefix {
    font-size: 0.875rem !important;
    color: #202328 !important;
  }

  [contenteditable]:not([contenteditable="false"]):focus {
    outline-style: none;
  }

  .multiselect-container {

    .optionListContainer {
      background-color: var(--background-contrast-grey);
    }

    input[type="checkbox"] {
      color: green;
    }

    .search-wrapper {
      border: none;
      background-color: $grey-form-field-bg;
      box-shadow: inset 0 -1px 0 0 $grey-link !important;
      border-radius: 0 !important;

      cursor: pointer;
      --idle: #0000;
      --hover: var(--background-contrast-grey-hover);
      --active: var(--background-contrast-grey-active);
      --data-uri-svg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23161616' d='m12 13.1 5-4.9 1.4 1.4-6.4 6.3-6.4-6.4L7 8.1l5 5z'/%3E%3C/svg%3E");
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: var(--background-contrast-grey);
      background-image: var(--data-uri-svg);
      background-position: calc(100% - .5rem) 50%;
      background-repeat: no-repeat;
      background-size: 1.5rem 1.5rem;
      border-radius: .25rem .25rem 0 0;
      box-shadow: inset 0 -2px 0 0 var(--border-plain-grey);
      color: var(--text-title-grey);
      display: block;
      font-size: 1rem;
      line-height: 1.5rem;
      padding: .5rem 2.5rem .5rem 1rem;
      width: 100%;
    }
  }

  .fr-input,
  .fr-select {
    background-color: $grey-form-field-bg;
    box-shadow: inset 0 -1px 0 0 $grey-link !important;
    border-radius: 0 !important;

    &:focus {
      background-color: $grey-bg;
    }

    &:disabled,
    &[disabled] {
      opacity: 1;
      color: $grey-link-disabled !important;
      box-shadow: inset 0 -1px 0 0 $grey-border !important;
    }
  }

  .fr-select {
    background-size: 1.25rem 1.25rem;
  }

  .fr-radio-group {
    input[type="radio"] {
      & + label {
        margin-left: 1.75rem;
      }

      & + label:before {
        box-shadow: inset 0 0 0 1px $grey-link, inset 0 0 0 12px #ffffff, inset 0 0 0 12px $grey-link;
        width: 1.125rem;
        height: 1.125rem;
        left: -1.75rem;
        margin-top: 0.9rem;
      }
    }
  }

  .fr-checkbox-group {
    input[type="checkbox"] {
      & + label {
        margin-left: 1.75rem;
        display: inline;
      }

      & input:disabled {
        opacity: 0;
      }

      & + label:before {
        box-shadow: inset 0 0 0 1px $grey-link;
        width: 1.125rem;
        height: 1.125rem;
        left: -1.75rem;
        margin-top: 0.925rem;
      }

      & + label:after {
        margin-left: 0.2rem;
      }
    }

    input[type="checkbox"]:disabled {
      opacity: 0;
    }

    &.label-hide {
      label {
        display: block;
        margin: 0;
        padding: 0;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto !important;
        }
      }
    }
  }
}

.fr-app-bloc-filtre {
  &.agent-search-filtre {
    padding-top: 1.25rem !important;
  }

  .fr-grid-row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    div[class*="fr-col-"] {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.align-centre {
        margin: auto;
      }
    }
  }

  .fr-search-bar {
    > .fr-input-group {
      width: 100%;
    }
    .fr-btn {
      border-radius: 0;
    }
    button.fr-btn:before {
      font-size: 1.4rem;
    }
  }
}

.tables-ref {
  .fr-app-form {
    .fr-btns-group {
      padding-top: 0;
    }
  }
}

.fr-app-badge-flag-formation {
  padding: 0rem 3rem 0rem 3rem;
  height: 3rem;
}

.fr-app-form-field-with-prefix {
  position: relative;

  .form-field-prefix {
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
  }

  &.histo-modules-select-cursus {
    .form-field-prefix {
      left: -4rem;
    }
  }
}

@media (min-width: 768px) {
  .fr-app-form,
  .fr-app-bloc-filtre {
    div[class*="fr-col-"] {
      &.fr-col-6:not(.align-centre):not(.reverse) {
        &:nth-child(odd) {
          padding-right: 15px;
        }

        &:nth-child(even):not(.reverse) {
          padding-left: 15px;
        }
      }

      &.fr-col-6 {
        &:nth-child(odd) {
          &.reverse {
            padding-left: 15px;
          }
        }

        &:nth-child(even) {
          &.reverse {
            padding-right: 15px;
          }
        }
      }
    }
  }

  .fr-app-bloc-filtre {
    .fr-grid-row {
      margin-left: -15px;
      margin-right: -15px;

      div[class*="fr-col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

/* APP ICPE */
.fr-app-icpe {
  // Form
  .fr-app-form {
    .fr-input,
    .fr-select {
      &:focus {
        @include fr-app-input-select_focus($app-green-typo);
      }
    }

    .fr-radio-group {
      input[type="radio"] {
        &:checked + label:before {
          @include fr-app-input_radio($app-green-typo);
        }
      }
    }

    .optionContainer {
      li.option.selected,
      .highlight {
        background: $app-green-typo !important;
        color: white;
      }
      li:hover{
        background: gray !important;
      }
      li.option > input[type="checkbox"] {
        display: none;
      }
    }

    .search-wrapper {
      .chip {
        background-color: $app-green-typo;
      }
    }

    .fr-checkbox-group {
      input[type="checkbox"] {
        &:checked + label:before {
          @include fr-app-input_checkbox($app-green-typo);
        }
      }
    }
  }
}

/* APP EAUNATURE */
.fr-app-eaunature {
  // Form
  .fr-app-form {
    .fr-input,
    .fr-select {
      &:focus {
        @include fr-app-input-select_focus($app-orange-typo);
      }
    }

    .optionContainer {
      li.option.selected,
      .highlight {
        background: $app-orange-typo !important;
        color: white;
      }
      li:hover{
        background: gray !important;
      }
      li.option > input[type="checkbox"] {
        display: none;
      }
    }

    .search-wrapper {
      .chip {
        background-color: $app-orange-typo;
      }
    }

    .fr-radio-group {
      input[type="radio"] {
        &:checked + label:before {
          @include fr-app-input_radio($app-orange-typo);
        }
      }
    }

    .fr-checkbox-group {
      input[type="checkbox"] {
        &:checked + label:before {
          @include fr-app-input_checkbox($app-orange-typo);
        }
      }
    }
  }
}
