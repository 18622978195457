/* Main Nav */
.fr-sidemenu {
  &.fr-app-main-nav {
    background-color: #ffffff;
    margin: 1.5rem 0 0.5rem;
    padding: 0;
    filter: drop-shadow(var(--raised-shadow));
    box-shadow: none;

    .fr-sidemenu__inner {
      box-shadow: none;
      max-height: none;

      button.fr-sidemenu__btn {
        &[aria-expanded]:after {
          margin-right: 0;
        }
      }

      & > button.fr-sidemenu__btn {
        text-transform: uppercase;
        letter-spacing: 0.02rem;
        font-size: 0.75rem;
        padding: 0.75rem 0.875rem;
        position: relative;

        &::before {
          content: "\ef32";
          font-family: "remixicon" !important;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 1.5rem;
          mask-image: unset;
          width: auto;
          height: auto;
          background-color: transparent;
        }

        &[aria-expanded="true"] {
          &::after {
            content: "" !important;
            display: inline-block;
            height: 0;
            width: 0;
            border-right: 0.5625rem solid #ffffff;
            border-left: 0.5625rem solid #ffffff;
            margin: auto;
            left: 2.5rem;
            position: absolute;
            bottom: -0.6rem;
            transform: none;
            transition: none;
          }
        }
      }

      & > .fr-collapse {
        &.fr-collapse--expanded {
          padding-top: 1.75rem;
          padding-bottom: 1.625rem;
        }

        // Général
        ul.fr-sidemenu__list {
          margin: 0;

          li.fr-app-menu-item {
            button.fr-sidemenu__btn,
            a.fr-app-menu-link {
              color: $grey-typo;
              padding: 0.5rem;
              min-height: 2.75rem;
              font-size: 0.925rem;
              font-weight: normal;
              border-bottom: solid 1px $grey-border;

              &:hover,
              &:focus,
              &:active {
                background-image: none;
                --blend: inherit;
              }

              &:hover {
                color: #ffffff;
              }
            }

            .ri-home-2-line {
              font-size: 1rem;
              margin-top: 0.125rem;
              margin-right: 0.45rem;
            }

            a.fr-app-menu-link {
              &[aria-current]:after {
                //Reboot SideMenu
                display: none;
              }

              &:active,
              &.active {
                font-family: $font-Marianne-Bold;
                font-weight: normal;

                .ri-home-2-line:before {
                  content: "\EE18";
                }
              }
            }

            button.fr-sidemenu__btn {
              &:before {
                font-family: remixicon;
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: normal;
                transform: none;
                margin-left: auto;
                margin-right: 0;
              }

              &:active,
              &[aria-expanded="true"] {
                font-family: $font-Marianne-Bold;
                font-weight: normal;
                background-color: $grey-bg;

                &:hover {
                  color: #ffffff !important;
                }
              }
            }
          }
        }

        // Niveau 1
        & > ul.fr-sidemenu__list {
          border-top: solid 1px $grey-border;

          & > li.fr-app-menu-item {
            & > button.fr-sidemenu__btn,
            & > .formation__link,
            & > .suiviactivite__link {
              text-transform: uppercase;
              font-size: 1rem !important;
            }

            // Niveau 2
            & > .fr-collapse {
              & > ul.fr-sidemenu__list {
                background-color: $grey-bg;

                & > li.fr-app-menu-item {
                  // Niveau 2+
                  button.fr-sidemenu__btn {
                    &:before {
                      line-height: 1;
                      background-color: $grey-link;
                      color: #ffffff;
                      width: 1rem;
                      height: 1rem;
                      border-radius: 50%;
                    }

                    &,
                    &[aria-expanded="true"] {
                      &:hover {
                        &:before {
                          background-color: #ffffff !important;
                        }
                      }
                    }
                  }

                  // Niveau 3+
                  .fr-collapse {
                    a.fr-app-menu-link {
                      padding-left: 2rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .fr-sidemenu {
    &.fr-app-main-nav {
      background-color: transparent;
      position: relative;

      .fr-sidemenu__inner {
        padding: 0.2rem;
        max-height: none;

        & > button.fr-sidemenu__btn {
          display: flex;
        }

        & > .fr-collapse {
          --collapse-max-height: 0;
          --collapse: -99999px;
          --collapser: "";
          max-height: 0;
          max-height: var(--collapse-max-height);
          overflow: hidden;
          transition: visibility 0.3s;
          margin: -0.25rem 0 0;
          padding: 0.25rem 1rem 0;
          background-color: #ffffff;

          &:not(.fr-collapse--expanded) {
            visibility: hidden;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .fr-sidemenu {
    &.fr-app-main-nav {
      display: block;
      margin: 0;
      background-color: #ffffff;
      position: sticky;

      .fr-sidemenu__inner {
        padding: 1.5rem 0;
        max-height: none;

        & > button.fr-sidemenu__btn {
          display: none;
        }

        & > .fr-collapse {
          max-height: none;
          margin: -0.25rem 0.25rem 0;
          padding: 0.25rem 0.25rem 0;

          &:not(.fr-collapse--expanded) {
            visibility: visible;
          }

          &.fr-collapse--expanded {
            padding-top: 0.25rem;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

/* APP ICPE */
.fr-app-icpe {
  // Main Nav
  .fr-sidemenu {
    &.fr-app-main-nav {
      .fr-sidemenu__inner {
        & > button.fr-sidemenu__btn {
          color: $app-green-typo;
          background-color: $app-green-bg;

          &[aria-expanded="true"] {
            &::after {
              border-top: 0.625rem solid $app-green-bg;
            }
          }
        }

        & > .fr-collapse {
          // Général
          ul.fr-sidemenu__list {
            margin: 0;

            li.fr-app-menu-item {
              button.fr-sidemenu__btn,
              a.fr-app-menu-link {
                &:hover {
                  background-color: $app-green-typo;
                }
              }

              a.fr-app-menu-link {
                &:active,
                &.active {
                  border: none;
                  background-color: $app-green-bg;
                  color: $app-green-typo;
                }
              }

              button.fr-sidemenu__btn {
                &:active,
                &[aria-expanded="true"] {
                  color: $app-green-typo;

                  &:hover {
                    background-color: $app-green-typo;
                  }
                }
              }
            }
          }

          // Niveau 1
          & > ul.fr-sidemenu__list {
            & > li.fr-app-menu-item {
              // Niveau 2
              & > .fr-collapse {
                & > ul.fr-sidemenu__list {
                  & > li.fr-app-menu-item {
                    // Niveau 2+
                    button.fr-sidemenu__btn {
                      &:active,
                      &[aria-expanded="true"] {
                        &:before {
                          background-color: $app-green-typo;
                        }
                      }

                      &,
                      &[aria-expanded="true"] {
                        &:hover {
                          &:before {
                            color: $app-green-typo;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* APP EAUNATURE */
.fr-app-eaunature {
  // Main Nav
  .fr-sidemenu {
    &.fr-app-main-nav {
      .fr-sidemenu__inner {
        & > button.fr-sidemenu__btn {
          color: $app-orange-typo;
          background-color: $app-orange-bg;

          &[aria-expanded="true"] {
            &::after {
              border-top: 0.625rem solid $app-orange-bg;
            }
          }
        }

        & > .fr-collapse {
          // Général
          ul.fr-sidemenu__list {
            margin: 0;

            li.fr-app-menu-item {
              button.fr-sidemenu__btn,
              a.fr-app-menu-link {
                &:hover {
                  background-color: $app-orange-typo;
                }
              }

              a.fr-app-menu-link {
                &:active,
                &.active {
                  background-color: $app-orange-bg;
                  color: $app-orange-typo;
                }
              }

              button.fr-sidemenu__btn {
                &:active,
                &[aria-expanded="true"] {
                  color: $app-orange-typo;

                  &:hover {
                    background-color: $app-orange-typo;
                  }
                }
              }
            }
          }

          // Niveau 1
          & > ul.fr-sidemenu__list {
            & > li.fr-app-menu-item {
              // Niveau 2
              & > .fr-collapse {
                & > ul.fr-sidemenu__list {
                  & > li.fr-app-menu-item {
                    // Niveau 2+
                    button.fr-sidemenu__btn {
                      &:active,
                      &[aria-expanded="true"] {
                        &:before {
                          background-color: $app-orange-typo;
                        }
                      }

                      &,
                      &[aria-expanded="true"] {
                        &:hover {
                          &:before {
                            color: $app-orange-typo;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
