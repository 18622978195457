/* Table */
.tableContainer,
.tableContainerWithLoader,
.fr-table.fr-app-table,
.fr-table.fr-app-table table,
.fr-table.fr-app-table:not(.fr-table--no-caption) caption {
  width: calc(100vw - 4rem);
}

.tableContainer,
.tableContainerWithLoader {
  max-height: calc(100vh + 4rem);
  padding-bottom: 0.625rem;
  overflow: auto;
}

.fr-app-selected-row {
  background-color: $grey-body-bg !important;
  font-style: italic;
  color: $grey-link-disabled;
}

.fr-table {
  &.fr-app-table {
    margin-bottom: 1.25rem;

    &.no-padding-top {
      padding-top: 0;
    }

    &.padding-top-lg {
      padding-top: 1.5rem;
    }

    &.padding-top-xl {
      padding-top: 1.725rem;
    }

    &.no-margin-bottom {
      margin-bottom: 0;
    }

    &.margin-bottom-lg {
      margin-bottom: 1.5rem;
    }

    &.margin-bottom-xl {
      margin-bottom: 1.725rem;
    }

    &.no-caption {
      caption {
        display: none;
      }

      thead {
        top: 0;
      }
    }

    &.actions-align-center {
      tbody {
        td {
          span {
            &.fr-app-icon,
            &[class*="ri-"] {
              margin: auto;
            }
          }
        }
      }
    }

    &.with-pdf-before-actions {
      tbody {
        td {
          span {
            &[class*="ri-file-pdf-"] {
              margin: auto;
            }
          }
        }
      }
    }

    &.two-cols-list-tab {
      thead {
        th,
        td {
          text-align: center !important;
        }
      }
      tbody {
        td {
          vertical-align: top;
          li {
            text-align: left !important;
          }
        }
      }
      .btn-float-right {
        .fr-btn {
          float: right;
          margin-top: -0.2rem;
          margin-right: 0.2rem;
        }
      }
    }

    &,
    table {
      //overflow: unset;
      display: table;

      &:not(.fr-app-actions-btns-container-tab) {
        padding-bottom: 1px;
      }
    }

    tr {
      th:not(:first-child),
      td:not(:first-child) {
        text-align: center;
      }

      th,
      td {
        &.text-align-center {
          text-align: center;
        }

        &.text-md {
          font-size: 1rem;
        }

        &.red-error {
          color: $error-425;
        }

        &.warning {
          color: $warning-425;
        }

        &.success {
          color: $success-425;
        }
      }

      td {
        &.spacing-md {
          padding: 0.75rem 1rem 1rem;
        }

        &.border-top {
          border-top: solid 1px $grey-border;
        }

        &.light-gray-bg {
          background-color: $grey-form-field-bg;

          .fr-input {
            background-color: #ffffff;
          }
        }
      }
    }

    &:not(.fr-table--no-caption) caption {
      position: sticky;
      background: white;
      height: 4rem;
      top: 0;
      left: 0;
      margin: 0 !important;
    }

    thead {
      position: sticky;
      top: 4rem;
      background-color: $grey-bg;
      box-shadow: inset 0 -2px 0 0 $grey-link;

      th,
      td {
        font-family: $font-Marianne-Bold;
        font-weight: normal;
        border-right: solid 1px $grey-border;
        font-size: 0.75rem;
        padding: 0.5rem 0.625rem;
      }

      .table-column-header {
        display: block;
      }

      .sortable {
        .table-column-header {
          position: relative;

          span[class*="fr-fi-arrow-"] {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -0.5rem;
            margin: auto;
            height: 1rem;
            line-height: 1rem;

            &::before {
              font-size: 1rem;
            }
          }
        }
      }
    }

    tbody {
      background-color: transparent;
      border-left: solid 1px $grey-border;

      tr {
        &:not(.fr-app-green-bg, .fr-app-pink-bg, .fr-app-red-bg, .fr-app-orange-bg, .fr-app-yellow-bg, .fr-app-selected-row) {
          background-color: #ffffff !important;
        }

        &:not(.no-hover-effect, .fr-app-green-bg, .fr-app-pink-bg, .fr-app-red-bg, .fr-app-orange-bg, .fr-app-yellow-bg, .fr-app-selected-row):hover {
          background-color: $grey-form-field-bg !important;
        }
      }

      td {
        border-bottom: solid 1px $grey-border;
        border-right: solid 1px $grey-border;
        padding: 0.6rem 0.5rem;

        &:first-child {
          border-left: solid 1px $grey-border;
        }

        span {
          &.fr-app-icon,
          &[class*="ri-"] {
            display: block;
            width: 1.5rem;
            height: 1.5rem;
          }

          &[class*="ri-"] {
            font-size: 1.5rem;

            &.ri-flag-fill {
              font-size: 1.35rem;
            }
          }
        }

        .fr-radio-group {
          display: inline-block;
        }

        &.col-actions {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }

        table.fr-app-actions-btns-container-tab {
          table-layout: fixed;
          width: auto;
          margin: auto;

          tr {
            background-color: transparent !important;

            td {
              border: 0;
              padding: 0 0.25rem;
              text-align: center;
              line-height: 1;
              width: 1.5rem !important;
              height: 1.5rem !important;
            }
          }
        }

        .btn-action-trigger {
          background-image: none;
          box-shadow: none;

          &[type="button"] {
            color: $grey-link;
          }
        }
      }
    }

    .fr-btns-group {
      margin-top: 1.25rem;
    }
  }
}

.fr-app-descriptif {
  margin-top: 0.8725rem !important;
  margin-bottom: 1.25rem !important;
}

.fr-app-dashboard {
  tr {
    th,
    td {
      &:last-child {
        min-width: 65px;
      }
    }
  }
}

.postes-tab {
  tr {
    td {
      .fr-app-icon-file-PDF {
        margin: auto;
      }
    }
  }
}

.habilitations-tab {
  tr {
    th:first-child,
    td:first-child {
      text-align: center;
    }
  }
}

.cursus-recap-tab {
  tr {
    th,
    td {
      &:first-child {
        text-align: center;
        width: 2.55rem;
      }

      &.light-gray-bg {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.histo-modules-tab {
  tr {
    th,
    td {
      &:first-child {
        text-align: center;
        width: 2.55rem;
      }
    }

    td {
      &:last-child {
        position: relative;

        .fr-app-green-bg {
          background-color: $app-green-bg;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .tableContainer,
  .tableContainerWithLoader,
  .fr-table.fr-app-table,
  .fr-table.fr-app-table table,
  .fr-table.fr-app-table:not(.fr-table--no-caption) caption {
    width: 100%;
  }

  .tableContainer,
  .tableContainerWithLoader {
    max-height: 768px;
  }

  .fr-table {
    &.fr-app-table:not(.fr-app-table-word-break-xl) {
      tr {
        th,
        td {
          word-break: break-word;
        }
      }
    }
  }

  .fr-app-section,
  .fr-tabs__panel,
  .fr-app-form,
  .fr-app-modal {
    .fr-table {
      &.fr-app-table {
        &.actions-col-xs {
          tr {
            th,
            td {
              &:last-child {
                width: 62px;
              }
            }
          }
        }

        &.with-pdf-before-actions {
          tr {
            th,
            td {
              &:nth-last-child(2) {
                width: 62px;
              }
            }
          }
        }

        &.actions-col-sm {
          tr {
            th,
            td {
              &:last-child {
                width: 100px;
              }
            }
          }
        }

        &.actions-col-md {
          tr {
            th,
            td {
              &:last-child {
                width: 160px;
              }
            }
          }
        }

        table {
          display: table;
          table-layout: fixed;
          word-break: break-all;
        }
      }
    }
  }

  .fr-app-dashboard {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 0.9375rem;
          padding-right: 0.9375rem;
        }

        &:last-child {
          width: 183px;
        }
      }
    }
  }

  .refus-creation-agent-tab {
    tr {
      th,
      td {
        &:nth-child(4) {
          min-width: 150px;
        }
        &:last-child {
          max-width: 300px;
        }
      }
    }
  }
  .creation-agent-tab {
    tr {
      th,
      td {
        &:first-child {
          width: 108px;
        }
        &:nth-child(4) {
          width: 110px;
        }
      }
    }
  }
  .agents-departs-tab {
    tr {
      th,
      td {
        &:first-child,
        &:nth-child(5) {
          width: 115px;
        }
      }
    }
  }
  .agents-non_affectes-tab {
       tr {
         th {
           &:first-child {
             text-align: center;
           }
         }
         th,
         td {
           &:first-child {
             width: 110px;
           }
           &:nth-child(2) {
             width: 100px;
           }
           // Affichage des dates
           &:nth-child(3),
           &:nth-child(6){
             width: 100px;
           }
         }
       }
     }
  .postes-tab {
    tr {
      th,
      td {
        &:first-child,
        &:nth-child(2) {
          width: 95px;
        }

        &:last-child {
          width: 42px;
        }
      }
    }

    &.with-pdf-before-actions {
      tr {
        th,
        td {
          &:nth-last-child(2) {
            width: 42px !important;
          }

          &:last-child {
            width: 76px;
          }
        }
      }
    }
  }
  .agents-prise-de-poste-tab {
    tr {
      th,
      td {
        &:nth-last-child(2),
        &:last-child {
          width: 24%;
        }
      }
    }
  }
  .agents-semaine-consolidation-tab {
    tr {
      th,
      td {
        &:nth-child(4) {
          width: 10%;
        }
      }
    }
  }

  .consulter-agents-habilitables-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 150px !important;
      }
    }
  }

  .consulter-arrivees-programmees-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 150px !important;
      }
    }
  }
  .arretes-nomination-commissionnement-tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
      th:last-child,  // Actions
      td:last-child{
        width: 75px !important;
      }
    }
  }

  .mails-cc-tab {
    tr {
      th:first-child,   // Email
      td:first-child {
        text-align: center;
        width: 200px !important;
      }
      th:nth-child(3),  // Profil
      td:nth-child(3){
        width: 60px !important;
      }
      th:nth-child(4),  // Organisme gestionnaires
      td:nth-child(4){
        width: 130px !important;
      }
      th:nth-child(5),  // Régions
      td:nth-child(5){
        width: 80px !important;
      }
      th:last-child,  // Actions
      td:last-child{
        width: 70px !important;
      }
    }
  }

  .prestation-serment-a-renseigner-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 150px !important;
      }
    }
  }

  .commissionnements_bordereau_a_imprimer-tab {
    tr {
      th:first-child   // Nom
      {
        width: 90px !important;
      }
      td:first-child {    // Nom
        width: 90px !important;
        text-align: center;
      }
      th:nth-child(2),  // Prénom
      td:nth-child(2) {
        width: 90px !important;
      }
      th:nth-child(3),  // Service Destinataire
      td:nth-child(3) {
        width: 150px !important;
      }
      th:nth-child(4),  // Destinataire
      td:nth-child(4) {
        width: 150px !important;
      }
      th:nth-child(5),  // Edité le
      td:nth-child(5) {
        width: 100px !important;
      }
    }
  }
  .agents-changement-de-zone-refuses-tab {
     tr {
       th:first-child,   // nom
       td:first-child,
       th:nth-child(2),
       td:nth-child(2) {
         padding: 0px;
         text-align: center;
         width: 115px !important;
       }
       th:nth-child(3),  // Type com.
       td:nth-child(3) {
         padding: 0px;
         width: 150px !important;
       }
       th:nth-child(7),  // Actions
       td:nth-child(7) {
         width: 75px !important;
       }
     }
   }
  .agents-decommissionnements-refuses-tab {
    tr {
      th:first-child,   // nom
      td:first-child,
      th:nth-child(2),  // prénom
      td:nth-child(2) {
        padding: 0px;
        text-align: center;
        width: 115px !important;
      }
      th:nth-child(3),  // Départ
      td:nth-child(3) {
        padding: 0px;
        width: 110px !important;
      }
      th:nth-child(5),  // Type com.
      td:nth-child(5) {
        padding: 0px;
        width: 100px !important;
      }
      th:nth-child(7),  // Actions
      td:nth-child(7) {
        width: 75px !important;
      }
    }
  }
  .agents-cartes-a-detruire-tab {
    tr {
      th:first-child,   // nom
      td:first-child,
      th:nth-child(2),  // prénom
      td:nth-child(2) {
        padding: 0px;
        text-align: center;
        width: 130px !important;
      }
      th:nth-child(4),  // Numéro de carte
      td:nth-child(4) {
        padding: 0px;
        width: 60px !important;
      }
      th:nth-child(5),  // Type Com.
      td:nth-child(5) {
        padding: 0px;
        width: 70px !important;
      }
      th:nth-child(7),  // Motif
      td:nth-child(7) {
        width: 150px !important;
      }
    }
  }
  .agents-cartes-a-renouveler-tab {
    tr {
      th:first-child,   // nom
        td:first-child,
        th:nth-child(2),  // prénom
        td:nth-child(2) {
        padding: 0px;
        text-align: center;
        width: 130px !important;
      }
      th:nth-child(4),  // Type Com.
        td:nth-child(4) {
        padding: 0px;
        width: 110px !important;
      }
      th:nth-child(5),  // Expiration
        td:nth-child(5) {
        width: 100px !important;
      }
    }
  }
  .agents-decommissionnements-tab {
    tr {
      th:first-child,   // nom
      td:first-child,
      th:nth-child(2),  // prénom
      td:nth-child(2) {
        padding: 0px;
        text-align: center;
        width: 115px !important;
      }
      th:nth-child(3),  // Départ
      td:nth-child(3) {
        padding: 0px;
        width: 110px !important;
      }
      th:nth-child(5),  // Type com.
      td:nth-child(5) {
        padding: 0px;
        width: 100px !important;
      }
      th:nth-child(7),  // Actions
      td:nth-child(7) {
        width: 75px !important;
      }
    }
  }
  .agents-changement-de-zone-tab {
    tr {
      th:first-child,   // nom
      td:first-child,
      th:nth-child(2),
      td:nth-child(2) {
        padding: 0px;
        text-align: center;
        width: 115px !important;
      }
      th:nth-child(3),  // Type com.
      td:nth-child(3) {
        padding: 0px;
        width: 150px !important;
      }
    }
  }
  .commissionnements-carte-a-editer-tab {
    tr {
      th:first-child,   // a editer
      td:first-child {
        padding: 0px;
        text-align: center;
        width: 50px !important;
      }
      th:nth-child(2),  // Nom
      td:nth-child(2),
      th:nth-child(3),  // Prénom
      td:nth-child(3) {
        width: 90px !important;
      }
      th:nth-child(4),  // Type Com.
      td:nth-child(4) {
        padding: 0px;
        width: 75px !important;
      }
      th:nth-child(5),  // Commissionnement
      td:nth-child(5) {
        width: 130px !important;
      }
      th:nth-child(6),  // Zone géographique
      td:nth-child(6) {
        width: 140px !important;
      }
      th:nth-child(7),  // Motif
      td:nth-child(7) {
        width: 150px !important;
      }
    }
  }
  .commissionnements-carte-en-attente-edition-tab {
    // comme commissionnements_carte_a_editer-tab mais avec la première colonne en moins
    tr {
      th:nth-child(1),  // Nom
      td:nth-child(1),
      th:nth-child(2),  // Prénom
      td:nth-child(2) {
        width: 90px !important;
      }
      th:nth-child(3),  // Type Com.
      td:nth-child(3) {
        padding: 0px;
        width: 75px !important;
      }
      th:nth-child(4),  // Commissionnement
      td:nth-child(4) {
        width: 130px !important;
      }
      th:nth-child(5),  // Zone géographique
      td:nth-child(5) {
        width: 140px !important;
      }
      th:nth-child(6),  // Motif
      td:nth-child(6) {
        width: 150px !important;
      }
    }
  }
  .prestation-serment-refusees-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 130px !important;
      }
      th:nth-child(4),  // Date Prestation
      td:nth-child(4){
        padding: 0px;
        width: 110px !important;
      }
      th:nth-child(5),  // PS
      td:nth-child(5){
        padding: 0px;
        width: 30px !important;
        text-align: center;
      }
    }
  }

  .prestation-serment-a-valider-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 150px !important;
      }
      th:nth-child(4),  // Date de prestation
      td:nth-child(4),
      th:nth-child(5),  // Date demande
      td:nth-child(5) {
        padding: 0px;
        width: 130px !important;
      }

      th:last-child,   // Dl de la prestation de serment
      td:last-child {
        width: 50px !important;
      }
    }
  }
  .arretes-nomination-commissionnement-tab-without-action {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
    }
  }

  .arretes-nomination-decommissionnement-tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
      th:last-child,
      td:last-child {
        width: 75px !important;
      }
    }
  }
  .arretes-nomination-chgt-zones-tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
      th:last-child,
      td:last-child {
        width: 75px !important;
      }
    }
  }
  .arretes-nomination-modifs-commissionnement-tab {
     tr {
       th:first-child,   // Nom
       td:first-child {
         text-align: center;
         width: 150px !important;
       }
       th:last-child,
       td:last-child {
         width: 75px !important;
       }
     }
  }

  .prestation-serment-refusees-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 130px !important;
      }
      th:nth-child(4),  // Date Prestation
      td:nth-child(4){
        padding: 0px;
        width: 110px !important;
      }
      th:nth-child(5),  // PS
      td:nth-child(5){
        padding: 0px;
        width: 30px !important;
        text-align: center;
      }
    }
  }

  .prestation-serment-a-valider-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 150px !important;
      }
      th:nth-child(4),  // Date de prestation
      td:nth-child(4),
      th:nth-child(5),  // Date demande
      td:nth-child(5) {
        padding: 0px;
        width: 130px !important;
      }

      th:last-child,   // Dl de la prestation de serment
      td:last-child {
        width: 50px !important;
      }
    }
  }
  .arretes-nomination-commissionnement-tab-without-action {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
    }
  }

  .arretes-nomination-decommissionnement-tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
      th:last-child,
      td:last-child {
        width: 75px !important;
      }
    }
  }
  .arretes-nomination-decommissionnement-tab-without-action {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
    }
  }

  .action-en-cours_tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
        padding: 0px;
      }
      th:last-child,
      td:last-child {
        width: 150px !important;
        padding: 0px;
      }
    }
  }
  .action-decommissionnement-en-cours_tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
        padding: 0px;
      }
      // 2 - Motif du décommissionnement
      th:nth-child(3),  // Date de depart
      td:nth-child(3) {
        width: 115px !important;
        padding: 0px;
      }
      th:nth-child(4),  // Commentaire
      td:nth-child(4) {
        width: 250px !important;
        padding: 0px;
      }
      th:last-child,
      td:last-child {
        width: 100px;
        padding: 0px;
      }
    }
  }
  .arretes-nomination-chgt-zones-tab {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
      th:last-child,
      td:last-child {
        width: 75px !important;
      }
    }
  }
  .arretes-nomination-chgt-zones-tab-without-action {
    tr {
      th:first-child,   // Nom
      td:first-child {
        text-align: center;
        width: 150px !important;
      }
    }
  }
  .arretes-nomination-modifs-commissionnement-tab {
     tr {
       th:first-child,   // Nom
       td:first-child {
         text-align: center;
         width: 150px !important;
       }
       th:last-child,
       td:last-child {
         width: 75px !important;
       }
     }
  }

  .consulter-agents-habilites-tab {
    tr {
      th:first-child {
        text-align: center;
      }
      th:last-child,  // Actions
      td:last-child {
        width: 100px;
      }
    }
  }
  .consulter-agents-commissionnes-tab {
    tr {
      th:first-child{
        text-align: center;
      }
      th:first-child,   // Nom
      td:first-child {
        width: 110px !important;
        padding: 5px;
      }
      th:nth-child(2),  // Prenom
      td:nth-child(2){
        width: 90px !important;
        padding: 5px;
      }
      th:nth-child(4),  // Zone de commissionnement
      td:nth-child(4),
      th:nth-child(5),  // Commissionnement
      td:nth-child(5){
        padding: 5px;
        width: 130px;
      }
      th:nth-child(6),  // Date début
      td:nth-child(6),
      th:nth-child(7),  // Date fin
      td:nth-child(7){
        width: 90px !important;
        padding: 0px;
      }
    }
  }
  .formations-agent-cursus-tab {
    tr {
      th,
      td {
        &:first-child {
          width: 215px;
        }
      }
    }
  }
  /* 1 - Complète
     Cursus:
      - Prise de poste
      - Consolidation prise de poste
  */
  .formations-agent-cursus-modules-tab-1 {
    tr {
      th:first-child,  // Flag
      td:first-child{
        width: 40px !important;
      }
      th:nth-child(2),  // Session
      td:nth-child(2){
        width: 80px !important;
        padding: 0px;
      }
      th:nth-child(3),  // Module
      td:nth-child(3){
        width: 180px !important;
      }
      th:nth-child(4), // Date début
      td:nth-child(4),
      th:nth-child(5), // Date fin
      td:nth-child(5),
      th:nth-child(6), // Type
      td:nth-child(6),
      th:nth-child(7), // Etat
      td:nth-child(7) {
        width: 90px !important;
        padding: 0px;
      }
      th:last-child, // Colonne actions
      td:last-child {
        width: 100px !important;
      }
    }
  }
  /* 2 - Simplifiée
     Cursus:
      - Formation approfondie
  */
  .formations-agent-cursus-modules-tab-2 {
    tr {
      /* Flag de succès */
      th:first-child,
      td:first-child{
        width: 40px !important;
      }
      th:nth-child(2),  // Session
      td:nth-child(2){
        width: 80px !important;
        padding: 0px;
      }
      /* Colonne Module */
      th:nth-child(3),
      td:nth-child(3){
        width: 225px !important;
      }
      /* Session / Date début / Date Fin / Type / Etat */

      th:nth-child(4), // Date début
      td:nth-child(4),
      th:nth-child(5), // Date fin
      td:nth-child(5),
      th:nth-child(6), // Type
      td:nth-child(6),
      th:nth-child(7), // Etat
      td:nth-child(7) {
        width: 90px !important;
        padding: 0px;
      }
      th:last-child, // Colonne actions
      td:last-child {
        width: 100px !important;
      }
    }
  }
  /* 3 - A Distance
     Cursus:
      - Préparation prise de poste
  */
  .formations-agent-cursus-modules-tab-3 {
    tr {
      th:first-child, // Flag
      td:first-child{
        width: 40px !important;
      }
      th:nth-child(2), // Module
      td:nth-child(2){
        width: 180px !important;
      }
      th:nth-child(3), // Date début
      td:nth-child(3),
      th:nth-child(4), // Date Fin
      td:nth-child(4),
      th:nth-child(5), // Type
      td:nth-child(5),
      th:nth-child(6), // Etat
      td:nth-child(6) {
        width: 90px !important;
        padding: 0px;
      }
      th:nth-child(7), // Commentaire
      td:nth-child(7) {
        width: 150px !important;
        padding: 0px;
      }
      //th:last-child, // Colonne actions
      //td:last-child {
      //  width: 100px !important;
      //}
    }
  }

  .foad-tab {
    tr {
      th,
      td {
        &:nth-last-child(2) {
          width: 28%;
        }
      }
    }
  }
  .sessions-tab {
    tr {
      th:first-child,
      td:first-child,
      th:nth-child(7),
      td:nth-child(7) {
        width: 65px !important;
      }

      th:nth-child(2),
      td:nth-child(2),
      th:nth-child(3),
      td:nth-child(3) {
        width: 100px;
      }
    }
  }

  .notes-tab {
    tr {
      th:first-child,      // important
       td:first-child {
        text-align: center;
        width: 40px !important;
      }

      th:nth-child(2),      // Date
       td:nth-child(2) {
        text-align: center;
        width: 100px !important;
        padding: 0px;
      }

      th:nth-child(3),     // Origine
       td:nth-child(3) {
        text-align: center;
        width: 150px !important;
      }

      th:nth-child(4),     // Origine
       td:nth-child(4) {
        text-align: center;
        width: 150px !important;
      }

      th:last-child,
      td:last-child {
        width: 80px;
      }
    }
  }
  .commissionnements-tab {
    tr {
      th:first-child,     // action
       td:first-child {
        padding: 0px;
        text-align: center;
        width: 150px !important;
      }
      th:nth-child(2),      // Attribution
       td:nth-child(2) {
        width: 140px;
      }
      th:nth-child(3),    // Zone Géographique
       td:nth-child(3) {
        width: 175px;
      }
      th:nth-child(4),    // Notification de l'arrêté
       td:nth-child(4) {
        width: 100px;
      }
      th:nth-child(5),    // AC
       td:nth-child(5),
       th:nth-child(6),    // CZ
       td:nth-child(6),
       th:nth-child(7),    // AD
       td:nth-child(7),
       th:nth-child(8),   // CC
       td:nth-child(8) {
        padding: 0px;
        width: 30px;
      }
    }
  }
  .cartes-commissionnements-tab {
    tr {
      th:first-child,     // numéro de carte
      td:first-child {
        padding: 0px;
        text-align: center;
        width: 50px !important;
      }
    th:nth-child(2),      // Commissionnement
      td:nth-child(2) {
        width: 140px;
      }
      th:nth-child(3),    // Début
      td:nth-child(3),
      th:nth-child(4),    // Fin
      td:nth-child(4) {
        padding: 0px;
        width: 80px;
      }
      th:nth-child(5),    // Etat
      td:nth-child(5) {
        width: 100px;
      }
    }
  }
  .session-participants-tab {
    tr {
      th:first-child{
        width: 200px;
        text-align: center;
      }
      &:last-child {
        width: 106px;
      }
    }
    &.check-present {
      tr {
        th:first-child{
          width: 80px;
        }
        th:nth-child(2) {
          width: 200px;
        }
      }
    }
  }

  .cursus-recap-tab {
    tr {
      th,
      td {
        &:nth-child(2) {
          width: 81px;
        }
        &:last-child {
          width: 106px;
        }
      }
    }

    &.preparation-prise-poste {
      tr {
        th,
        td {
          &:nth-child(2) {
            width: 65px;
          }
          &:nth-last-child(3) {
            width: 90px;
          }
          &:nth-last-child(2) {
            width: 210px;
          }
          &:last-child {
            width: 80px;
          }
        }
      }
    }

    &.col-module-md {
      tr {
        th,
        td {
          &:nth-child(3) {
            width: 165px;
          }
        }
      }

      &.actions-col-xs {
        tr {
          th,
          td {
            &:nth-child(3) {
              width: 210px;
            }
          }
        }
      }
    }
  }

  .agents-demandes-inscriptions-tab {
    width: 110%;
  }

  .habilitations-agent-cursus-modules-tab {
    tr {
      th:first-child,  // Module
      td:first-child{
        width: 180px !important;
      }
      th:nth-child(2) {
        width: 100px !important;
      } // Date début
      td:nth-child(2) {
        width: 100px !important;
      }
      th:nth-child(3) {
        width: 100px !important;
      } // Date fin
      td:nth-child(3) {
        width: 100px !important;
      }
      th:nth-child(4), // Type
      td:nth-child(4),
      th:last-child, // Type
      td:last-child {
        width: 180px !important;
      }
    }
  }

  .commissionnement-agent-cursus-modules-validation-tab {
     tr {
       th:first-child {
         color: green;
         width: 75px !important;
         text-align: center;
       }
       th:nth-child(2) {
         color: red;
         width: 75px !important;
       }
       td:first-child,
       td:nth-child(2)
       {
         padding-left: 2em;
         width: 75px !important;
       }
       th:nth-child(4), // Date début
       th:nth-child(4),
       th:nth-child(5), // Date fin
       th:nth-child(5) {
         width: 100px !important;
       }
       th:last-child, // Type
       td:last-child {
         width: 180px !important;
       }
     }
   }

  .suivi-activite-tab {
    tr {
      th:first-child,
      td:first-child {
        text-align: center;
        width: 100px !important;
      }
      th:nth-child(3),
      td:nth-child(3) {
        text-align: center;
        width: 100px !important;
      }
    }
  }

  .stats-agents-commissionnes-habilitation {
    thead {
      tr {
        th {
          width: 150px;
        }
      }
    }
  }

  .table-stats-agents-suivi-formations-annee {
    thead {
      tr {
        th:first-child,
        td:first-child {
          width: 150px;
        }
      }
    }
  }

  .table-stats-agents-tb-suivi-pdp {
    thead {
      tr {
        th:first-child,
        td:first-child {
          width: 150px;
        }
      }
    }
  }

  .table-stats-agents-non-inscrits {
    thead {
      tr {
        th:first-child,
        td:first-child {
          width: 150px;
        }
        th:nth-child(2),
        td:nth-child(2),
        th:nth-child(3),
        td:nth-child(3),
        th:nth-child(4),
        td:nth-child(4),
        th:nth-child(5),
        td:nth-child(5),
        th:nth-child(6),
        td:nth-child(6),
        th:nth-child(7),
        td:nth-child(7),
        th:nth-child(8),
        td:nth-child(8),
        th:nth-child(9),
        td:nth-child(9),
        th:nth-child(10),
        td:nth-child(10),
        th:nth-child(12),
        td:nth-child(12) {
          width: 75px !important;
        }
        th:nth-child(11),
        td:nth-child(11),
        th:nth-child(13),
        td:nth-child(13),
        th:nth-child(14),
        td:nth-child(14) {
          width: 100px !important;
        }
      }
    }
  }

  .fr-app-switch-profil {
    thead {
      top: 0rem;
    }
  }

  .table-stats-agents-tb-suivi-pdp {
    thead {
      top: 0rem;
    }
  }

  .table-stats-agents-suivi-formations-annee {
    thead {
      top: 0rem;
    }
  }

  .btn-action-trigger {
    background-image: none;
    box-shadow: none;

    &[type="button"] {
      color: $grey-link;
    }
  }
  
  .fr-app-modal-note-icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .fr-table {
    &.fr-app-table.fr-app-table-word-break-xl {
      tr {
        th,
        td {
          word-break: break-word;
        }
      }
    }
  }

  .postes-tab {
    tr {
      th,
      td {
        &:first-child,
        &:nth-child(2) {
          width: 98px;
        }

        &:last-child {
          width: 60px;
        }
      }
    }

    &.with-pdf-before-actions {
      tr {
        th,
        td {
          &:nth-last-child(2) {
            width: 60px !important;
          }

          &:last-child {
            width: 100px;
          }
        }
      }
    }
  }

  .consulter-agents-habilites-tab {
    tr {
      th:first-child,   // Nom
      td:first-child,
      th:nth-child(2),  // Prenom
      td:nth-child(2) {
        width: 150px;
      }
      th:nth-child(4),  // Date de décision + tooltip info
      td:nth-child(4) {
        width: 130px;
        padding: 0px;
      }
    }
  }

  .habilitations-tab {
    tr {
      th:first-child,
      td:first-child {
        width: 170px;
      }
      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4) {
        width: 115px;
      }
      th:nth-child(5),
      td:nth-child(5) {
        width: 110px;
      }
    }
  }
}
