.fr-app-loading-container {
  text-align: center;

  > div {
    margin: auto;
    padding-top: calc(50vh - 5rem);
  }

  > p {
    margin: 7.215rem 0 0;
    letter-spacing: 0.015rem;
  }
}

/* Text */
.fr-app-text-align-center {
  text-align: center;
}
.fr-app-text-align-center-important {
  text-align: center !important;
}
.fr-app-text-align-right {
  text-align: right;
}

/* Button */
// Bouton primaire
@mixin fr-app-btn-bg-color($default-bg-color, $hover-bg-color, $focus-bg-color) {
  background-color: $default-bg-color;
  background-image: none;
  --blend: inherit;
  color: #ffffff;

  &:hover {
    background-color: $hover-bg-color;
    color: #ffffff;
  }

  &:focus {
    background-color: $focus-bg-color;
    color: $grey-link-active;
  }

  &:active {
    background-color: $hover-bg-color;
    color: $grey-link-active;
  }

  &:disabled,
  &[disabled] {
    background-color: $grey-bg;
    color: $grey-link-disabled;
  }
}

//Bouton secondaire
@mixin fr-app-btn-border-color($default-border-color, $hover-border-color, $focus-border-color) {
  box-shadow: inset 0 0 0 1px $default-border-color;
  color: $default-border-color;

  &:hover {
    box-shadow: inset 0 0 0 1px $hover-border-color;
    color: $hover-border-color;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px $focus-border-color;
    color: $focus-border-color;
  }

  &:active {
    box-shadow: inset 0 0 0 1px $hover-border-color;
    color: $hover-border-color;
  }

  &:disabled,
  &[disabled] {
    box-shadow: inset 0 0 0 1px $grey-border;
    color: $grey-link-disabled;
  }
}

.fr-app {
  .fr-btns-group--inline-md.fr-btns-group--center .fr-btn,
  .fr-btns-group--inline-md.fr-btns-group--left .fr-btn {
    margin-bottom: 0;
    padding: 0.4rem 1.5rem 0.6rem !important;
  }

  .fr-btn--sm {
    font-size: 0.75rem;
  }
}

@media (min-width: 48em) {
  .fr-app,
  .fr-app-modal {
    .fr-btns-group--inline-md.fr-btns-group--center .fr-btn,
    .fr-btns-group--inline-md.fr-btns-group--left .fr-btn {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }

    *.width-demi {
      width: 50%;
      margin: auto;
    }
  }
}

.fr-app-document-trigger {
  box-shadow: none !important;
  vertical-align: middle;

  span[class*="ri-"] {
    font-size: 1.25rem;
    vertical-align: -0.0875rem;
  }
}

/* List */
.fr-app-linklist-container {
  margin-top: 1.625rem;
  &.with-description {
    > .fr-app-linklist-label {
      color: $grey-typo;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 0.2rem;
    }

    > .fr-app-linklist {
      li {
        padding: 0.1rem 0;
        a {
          font-weight: normal;
          font-size: 0.875rem;

          span {
            text-decoration: underline;

            &[class^="ri-"] {
              font-size: 1rem;
              text-decoration: none;
              vertical-align: -0.2rem;
              margin-right: 0.15rem;
            }
          }
        }
      }
    }
  }
}

/* Tabs */
@mixin fr-app-tabs__tab($hover-bg-color) {
  &[aria-selected="true"] {
    background-color: #ffffff !important;
    color: $hover-bg-color;

    background-image: linear-gradient(0deg, $hover-bg-color, $hover-bg-color) ,
      linear-gradient(0deg, var(--border-default-grey), var(--border-default-grey)),
      linear-gradient(0deg, var(--border-default-grey), var(--border-default-grey)),
      linear-gradient(0deg, var(--background-default-grey), var(--background-default-grey));
    background-size: 100% 3px, 1px calc(100% - 1px), 1px calc(100% - 1px), 100% 3px;

  }

  &:not([aria-selected="true"]) {
    background-color: $grey-link;
    color: #ffffff !important;
    --blend: $hover-bg-color;
  }

  &:hover {
    background-color: $hover-bg-color;
    color: #ffffff;
  }

  &:disabled,
  &[disabled] {
    background-color: $grey-bg;
    color: $grey-link-disabled;
  }
}

.fr-tabs {
  &.fr-app-tabs {
    overflow: visible;

    &:after {
      box-shadow: none !important;
    }

    .fr-tabs__panel {
      &.background-transparent {
        padding: 0;
        background-color: transparent;
        box-shadow: none !important;
      }
    }

    &.tables-ref {
      .fr-app-bloc-title {
        margin-bottom: 0;

        .fr-accordion__btn {
          font-family: $font-Marianne-Bold;
          font-weight: normal;
          font-size: 1.375rem;
          line-height: 1.75rem;
          text-align: center;
          display: inline-block;
          padding: 1.2875rem 1rem 1.325rem;
          position: relative;
          background-color: #ffffff;

          &:before {
            content: "\EA4E";
            font-family: remixicon;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1.55rem;
            font-weight: normal;
            position: absolute;
            right: 1rem;
            margin-top: 0.15rem;
          }

          &:hover {
            background-image: none;
          }

          &.active {
            &:before {
              transform: rotate(180deg);
            }
          }
        }
      }

      .fr-accordion {
        background-color: #ffffff;

        .fr-collapse.fr-collapse--expanded {
          padding: 0.2rem 1.75rem 1.5rem;
          max-height: none;
        }
      }

      .fr-accordion__btn:not(.active),
      .fr-accordion {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
      }
    }

    &.agent-tabs {
      button#fr-tab-5 {
        background-color: $info-425 !important;
        @include fr-app-tabs__tab($info-425);

        &[aria-selected="true"] {
          background-color: #ffffff !important;
        }
      }
    }
  }
}

/* Bloc */
.fr-app-display-inline {
  display: inline;
}
.fr-app-display-inline-flex {
  display: inline-flex;
}
.fr-app-margin-left-05 {
  margin-left: 0.5rem !important;
}

.fr-app-bloc-recap {
  .fr-grid-row {
    & > .fr-col-4,
    & > .fr-col-6 {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  .recap-title {
    font-family: $font-Marianne-Regular !important;
    margin-bottom: 0.65rem;

    span.uppercase {
      text-transform: uppercase;
    }
  }

  .recap-title-as-p {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    font-family: roboto-regular, arial, sans-serif !important;
    color: rgb(32, 35, 40);
    font-weight: normal !important;
    margin-bottom: 1.5rem;
  }

  .recap-text {
    margin-bottom: 0.5rem;

    span:not([class*="ri-"]) {
      font-family: $font-Roboto-Bold !important;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 0.375rem;
      height: 0.375rem;
      background-color: $grey-typo;
      border-radius: 50%;
      vertical-align: 0.15rem;
      margin-right: 0.5rem;
    }
  }

  .photo-agent {
    background-color: $grey-bg;
    position: relative;

    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.25rem;
      height: 5.5rem;
      overflow: hidden;

      img {
        height: 100%;
      }
    }

    .toggle-edit-btn {
      position: absolute;
      background-color: $grey-link;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      top: -0.3rem;
      right: -0.325rem;
      padding: 0;
      background-image: none;

      span[class*="ri-"] {
        color: #ffffff;
      }
    }
  }

  &.agent-recap {
    position: relative;

    .fr-container-fluid {
      padding-right: 4.25rem;

      div[class*="fr-col-"] {
        padding-right: 15px;
      }
    }

    > .fr-container-fluid {
      min-height: 88px;
    }

    ul {
      list-style: none;

      &,
      li {
        margin: 0;
        padding: 0;
      }
    }

    .photo-agent {
      position: absolute;
      top: 1.65rem;
      right: 1.875rem;
    }
  }
}
.fr-app-margin-left-05 {
 margin-left: 5px;
}

/* Badge */
.fr-badge {
  &.fr-app-badge {
    &.purple {
      background-color: $purple-bg;
      color: $purple;
    }
  }
}

.fr-app-technical-error {
  width: 100% !important;
  padding: 1rem !important;
  margin-bottom: 1em !important;
}

/* APP */
div.fr-app > div.fr-container {
  padding-bottom: 3em;
}

body.fr-app-container {
  background-color: $grey-body-bg;
  min-height: 100%;
}

.fr-app,
.fr-app-modal {
  /* Reboot titre, texte, lien */
  color: $grey-typo;

  .red-error {
    color: $error-425;
  }

  .yellow-warning {
    color:  $yellow-warning-425;
  }
  .warning {
    color: $warning-425;
  }

  .autre-choix-session {
    color: $autre-choix-session-425;
  }

  .success {
    color: $success-425;
  }

  p:not(.fr-logo),
  td,
  td a:not(.fr-header__service-title),
  label,
  legend,
  input,
  select,
  textarea {
    font-family: $font-Roboto-Regular;
    letter-spacing: 0.0125rem;
  }

  a:not(.fr-header__service-title),
  button,
  th {
    font-family: $font-Marianne-Regular;
  }

  a:not(.fr-app-logo):not(.fr-header__service-title):not(.fr-app-btn_with_icon_v_header):not([type="button"]) {
    color: $grey-link;

    &:disabled,
    &[disabled] {
      color: $grey-link-disabled;
    }
  }

  .fr-btn {
    border-radius: 0.3875rem;
  }

  .fr-app-validation-habilitation-recap-cursus-caption {
    display: block;
    font-size: 1.2rem !important;
    text-align: left;
    padding-left: 2rem;
    line-height: 3rem;
  }

  .fr-app-table caption:not(.fr-app-small-caption, .fr-app-validation-habilitation-recap-cursus-caption),
  .fr-app-bloc-title {
    font-family: $font-Marianne-Bold !important;
    font-weight: normal !important;
    font-size: 1.375rem !important;
    line-height: 1.75rem !important;
    text-align: center;
    margin: 0 0 1.5rem;
  }

  .fr-app-small-caption {
    font-family: $font-Marianne-Bold !important;
    display: block;
    font-size: 1em;
    font-weight: normal;
  }

  .fr-highlight {
    margin-left: 0;
    box-shadow: inset 0.25rem 0 0 0 $info-425;
    background-color: $grey-form-field-bg;
    text-align: center;
    padding: 0.325rem 1.75rem 0.5rem;

    p {
      font-family: $font-Marianne-Regular;
    }
  }

  .fr-highlight-error {
    margin-left: 0;
    box-shadow: inset 0.25rem 0 0 0 $error-425;
    background-color: $grey-form-field-bg;
    text-align: center;
    padding: 0.325rem 1.75rem 0.5rem;

    p {
      font-family: $font-Marianne-Regular;
      margin-top: 1rem;
    }
  }
}

/* Main */
.fr-app-section,
.fr-tabs.fr-app-tabs .fr-app-tabs__panel,
.fr-tabs.fr-app-tabs .fr-tabs__panel {
  background-color: #ffffff;
  padding: 1.65rem 1rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}

.fr-app-section,
.fr-tabs.fr-app-tabs {
  margin-bottom: 1.875rem;
}

.fr-app-main-container {
  .fr-app-tab-ref {
    .fr-app-descriptif {
      margin-top: 0.75rem;
      margin-bottom: 0;
    }
  }
}

ul.fr-btns-group > li {
  padding-top: 0.3em;
}

/* Fil d'Ariane */
.fr-app-breadcrumb {
  padding-left: 1.25rem;
  margin-bottom: 1.5rem !important;

  .fr-breadcrumb__item {
    .fr-breadcrumb__link {
      margin-bottom: 0;
    }

    &:first-child {
      .fr-breadcrumb__link {
        &::before {
          font-family: "remixicon" !important;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\EE19";
          position: absolute;
          font-size: 0.875rem;
          top: 0;
          left: -1.25rem;
        }

        &:hover,
        &:active,
        &:focus {
          &::before {
            content: "\EE18";
          }
        }
      }
    }

    &:last-child {
      .fr-breadcrumb__link {
        box-shadow: none;
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }

    &:first-child:last-child {
      .fr-breadcrumb__link {
        &::before {
          content: "\EE18";
        }
      }
    }
  }
}

/* Footer */
.fr-app-footer {
  background-color: #414856;
  padding: 0.5rem 0 0.825rem;

  p,
  a {
    color: $grey-form-field-bg !important;
    font-size: 0.75rem !important;
    line-height: 1.25rem !important;
    display: inline;
  }

  a {
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      box-shadow: var(--link-underline);
      color: #ffffff !important;
    }
  }
}

@media (min-width: 768px) {
  /* Bloc */
  .fr-app-bloc-recap {
    .fr-grid-row {
      & > .fr-col-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        width: 33.33333%;
      }

      & > .fr-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }
}

@media (min-width: 992px) {
  /* Header */
  // Header haut
  .fr-app-header {
    .fr-header__body-row {
      padding: 0;
    }

    .fr-header__brand {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  // Header bas
  .fr-header__menu {
    .fr-app-btn_with_icon_v_header {
      &.btn-menu {
        margin-right: 2.25rem;
      }
    }
  }

  /* Main */
  .fr-app-main-nav-container {
    padding-right: 15px;

    &.fr-col-lg-4 {
      flex: 0 0 30%;
      max-width: 30%;
      width: 30%;
    }

    .fr-app-linklist-container {
      padding: 0 0.5rem;
    }
  }

  .fr-app-section,
  .fr-tabs.fr-app-tabs .fr-app-tabs__panel,
  .fr-tabs.fr-app-tabs .fr-tabs__panel {
    padding: 1.65rem 1.875rem;
  }

  .fr-app-main-container {
    padding-left: 15px;

    &.fr-col-lg-8 {
      flex: 0 0 70%;
      max-width: 70%;
      width: 70%;
    }
  }
}

@media (min-width: 1200px) {
  /* Main */
  .fr-app-main-nav-container {
    &.fr-col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
      width: 25%;
    }
  }

  .fr-app-main-container {
    &.fr-col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
      width: 75%;
    }
  }
}

.valider-habilitation-highlight {
  .fr-highlight {
    padding-bottom: 0;
    .fr-text--lg {
      font-size: 1.5rem !important;
      line-height: 2rem !important;
      margin: var(--text-spacing);
      padding: 0.5rem 0rem 1rem 0rem;
    }
  }
}

/* APP ICPE */
.fr-app-icpe {

  .fr-sidemenu__btn[aria-current]::before, .fr-sidemenu__link[aria-current]::before {
    background-color: $app-green-typo;
  }
    // Reboot
  &,
  &.fr-app-modal {
    .fr-app-bloc-title,
    .fr-app-table caption {
      color: $app-green-typo !important;
    }

    a:not(.fr-header__service-title):not(.fr-app-menu-link):not(.fr-btn):not(.fr-tabs__tab) {
      &:hover,
      &:active {
        color: $app-green-typo !important;
      }

      &:focus {
        color: $app-green-active !important;
      }
    }

    .fr-btn {
      &:not(.fr-btn--secondary) {
        @include fr-app-btn-bg-color($grey-link, $app-green-typo, $app-green-active);
      }

      &.fr-btn--secondary {
        @include fr-app-btn-border-color($grey-link, $app-green-typo, $app-green-active);
      }
    }

    .fr-app-document-trigger {
      &:hover {
        span[class*="ri-"] {
          color: $app-green-typo;
        }
      }
    }
  }

  // Fil d'Ariane
  .fr-app-breadcrumb {
    .fr-breadcrumb__item {
      .fr-breadcrumb__link {
        &:hover,
        &:active {
          &,
          &::before {
            color: $app-green-typo;
          }
        }

        &:focus {
          &,
          &::before {
            color: $app-green-active;
          }
        }
      }

      &:last-child {
        .fr-breadcrumb__link {
          color: $app-green-typo !important;
        }
      }

      &:first-child:last-child {
        &::before {
          color: $app-green-typo !important;
        }
      }
    }
  }

  // Tabs
  .fr-tabs {
    &.fr-app-tabs {
      .fr-tabs__tab {
        @include fr-app-tabs__tab($app-green-typo);
      }

      &.tables-ref {
        .fr-accordion__btn {
          font-weight: normal !important;

          &:hover,
          &.active {
            color: $app-green-typo;
            font-weight: normal;

            &:before {
              color: $app-green-typo;
            }
          }
          &[aria-expanded="true"] {
            font-family: $font-Marianne-Bold;
            font-weight: normal;
            background-color: var(--hover);
            color: $app-green-typo;

            &:hover {
              color: $app-green-typo;
            }
          }
        }
      }
    }
  }

  // Bloc
  .fr-app-bloc-recap {
    .photo-agent {
      .toggle-edit-btn {
        &:hover,
        &:focus,
        &:active {
          background-color: $app-green-typo;
        }
      }
    }
  }

  .fr-footer {
    box-shadow: 0 -2px 0 0 $app-green-typo,inset 0 -1px 0 0 var(--border-default-grey);
    background-color: white;
  }
}

/* APP EAUNATURE */
.fr-app-eaunature {
  // Reboot
  .fr-sidemenu__btn[aria-current]::before, .fr-sidemenu__link[aria-current]::before {
    background-color: $app-orange-typo;
  }

  &,
  &.fr-app-modal {
    .fr-app-bloc-title,
    .fr-app-table caption {
      color: $app-orange-typo !important;
    }

    a:not(.fr-header__service-title):not(.fr-app-menu-link):not(.fr-btn):not(.fr-tabs__tab) {
      &:hover,
      &:active {
        color: $app-orange-typo !important;
      }

      &:focus {
        color: $app-orange-active !important;
      }
    }

    .fr-btn {
      &:not(.fr-btn--secondary) {
        @include fr-app-btn-bg-color($grey-link, $app-orange-typo, $app-orange-active);
      }

      &.fr-btn--secondary {
        @include fr-app-btn-border-color($grey-link, $app-orange-typo, $app-orange-active);
      }
    }

    .fr-app-document-trigger {
      &:hover {
        span[class*="ri-"] {
          color: $app-orange-typo;
        }
      }
    }
  }

  // Fil d'Ariane
  .fr-app-breadcrumb {
    .fr-breadcrumb__item {
      .fr-breadcrumb__link {
        &:hover,
        &:active {
          &,
          &::before {
            color: $app-orange-typo;
          }
        }

        &:focus {
          &,
          &::before {
            color: $app-orange-active;
          }
        }
      }

      &:last-child {
        .fr-breadcrumb__link {
          color: $app-orange-typo !important;
        }
      }

      &:first-child:last-child {
        &::before {
          color: $app-orange-typo !important;
        }
      }
    }
  }

  // Tabs
  .fr-tabs {
    &.fr-app-tabs {
      .fr-tabs__tab {
        @include fr-app-tabs__tab($app-orange-typo);
      }

      &.tables-ref {
        .fr-accordion__btn {
          .fr-accordion__btn {
            font-weight: normal !important;

            &:hover,
            &.active {
              color: $app-orange-typo;
              font-weight: normal;

              &:before {
                color: $app-orange-typo;
              }
            }
            &[aria-expanded="true"] {
              font-family: $font-Marianne-Bold;
              font-weight: normal;
              background-color: var(--hover);
              color: $app-orange-typo;

              &:hover {
                color: $app-orange-typo;
              }
            }
          }
        }
      }
    }
  }

  // Bloc
  .fr-app-bloc-recap {
    .photo-agent {
      .toggle-edit-btn {
        &:hover,
        &:focus,
        &:active {
          background-color: $app-orange-typo;
        }
      }
    }
  }

  .fr-footer {
    box-shadow: 0 -2px 0 0 $app-orange-typo,inset 0 -1px 0 0 var(--border-default-grey);
    background-color: white;
  }
}

.fr-breadcrumb__list li:not(:first-child)::before {
  -webkit-mask-image: url('../../public/icons/system/arrow-right-s-line.svg') !important;
  mask-image: url('../../public/icons/system/arrow-right-s-line.svg') !important;
}

// hide content
.fr-display-none {
  display: none;
}

// hide content
.fr-display-flex {
  display: flex;
}

// div center content
.align-center {
  display: flex;
  justify-content: center;
}

// loader
.loader-center {
  display: flex;
  justify-content: center;
}

// loader
.loader-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
}
.loader-full-page-center {
  min-height: 466px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bloc-recap-header {
  background-color: #e5e5e5;
  box-shadow: inset 0 -2px 0 0 #6a6a6a;
  padding: 0.5rem 0.625rem;
  text-align: center;
  font-family: "marianne-bold", arial, sans-serif;
  font-weight: normal;
  border-right: solid 1px #cecece;
  font-size: 0.75rem;
}

.flex {
  display: flex;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding-top {
  padding-top: 0;
}

.guide-photos {
    display: block !important;
}

.histo-formations-pt {
  padding-top: 1.5rem;
}

.no-external-link-icon {
  &[target="_blank"]:after {
    content: none !important;
  }
}

.justify-content-center {
  justify-content: center;
}

.w-375 {
  width: 375px;
}

.badge-nb-notes {
  margin-left: 0.5rem;
}

.delete-button {
  height: 75%;
  margin-left: 1rem;
  margin-top: 2rem;
}

.accessibilite-liste-puce {
  margin-left: 1.5rem;
}

.no-margin-bottom {
  margin-bottom: 0px!important;
}

.habilitations-tab-ul {
  display: flex;
  --ul-type: none;
  padding-left: 0;
  justify-content: center;
}

.habilitation-button-tooltip:hover {
  background-color: unset !important;
}

.delete-button {
    margin: 0; 
    padding: 0;
}

.delete-button:hover {
    box-shadow: none!important;
    color: $app-green!important;
    background-color: unset!important;  
}

.switch-profil-title {
  font-size: 1rem !important;
  letter-spacing: 0.0125rem;
  color: #202328;
}
